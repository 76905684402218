<!--The content below is only a placeholder and can be replaced.-->
<header class="header">
	<div class="brand container">
		<div class="logo">
			<a routerLink="/" title="Home - NACCAP" style="border: none;"><img src="assets/images/naccap_header_logo.svg"></a>
		</div>
		<div class="mobile__nav" [class.open]="isMenuExpanded">
			<a class="nav__toggle" (click)="toggleMobileNav()"></a>
		</div>
		<div class="login">
			<ng-container *ngIf="!sessionUser || !sessionUser.userId">
				<rd-login type="inline" [forgotPasswordUrl]="urls['forgot-password']"></rd-login>
			</ng-container>
			<ng-container *ngIf="sessionUser && sessionUser.userId">
				<a class="btn btn--ghost btn--ghost--blue" [routerLink]="urls['my-account']">My Account</a>
				<a class="btn btn--ghost btn--ghost--blue" [routerLink]="urls['report-history']">Export report history</a>
				<a *ngIf="sessionUser.isAdmin" class="btn btn--ghost btn--ghost--blue" [href]="environment.apiUrl + '/admin'">Administration</a>
				<a class="btn btn--blue" (click)="logout()">Logout</a>
			</ng-container>
		</div>
	</div>
</header>
<nav class="nav">
	<div class="container">
		<ng-container *ngIf="!sessionUser || !sessionUser.userId">
			<div class="mobile__login">
				<a href="/login" class="btn btn--blue">Log in</a>
			</div>
		</ng-container>
		<ul class="navbar-nav" [class.open]="isMenuExpanded" rdDropdown>
			<li *ngFor="let item of navigation" routerLinkActive="active" class="nav-item" [class.dropdown]="item.children.length" [class.mobile__submenu--open]="item._expand">
				<a *ngIf="item.children.length" class="dropdown-toggle" #dropdownTrigger (click)="navigate(item.url)">
					{{ item.nav_title ? item.nav_title : item.title }}
				</a>
				<div *ngIf="item.children.length" class="mobile__submenu-button" (click)="item._expand = !item._expand"></div>
				<div class="dropdown-menu" aria-labelledby="navbarDropdown" #dropdown>
					<ng-container *ngTemplateOutlet="navAbsContent; context: {item: item}"></ng-container>
					<ng-container *ngTemplateOutlet="navFunnelContent; context: {item: item}"></ng-container>
					<a *ngFor="let item of item.children" [routerLink]="item.url" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="dropdown-item">{{ item.nav_title ? item.nav_title : item.title }}</a>
				</div>
				<a *ngIf="!item.children.length" #dropdownTrigger [routerLink]="item.url" routerLinkActive="active">{{ item.nav_title ? item.nav_title : item.title }}</a>
			</li>
			<li *ngIf="urls && sessionUser && sessionUser.userId" class="nav-item dropdown mobile__my-account" [class.mobile__submenu--open]="expandMobile">
				<a class="dropdown-toggle" routerLinkActive="active" [routerLink]="urls['my-account']">My Account</a>
				<div class="mobile__submenu-button" (click)="expandMobile = !expandMobile"></div>
				<div aria-labelledby="navbarDropdown" class="dropdown-menu">
					<a class="dropdown-item" routerLinkActive="active" [routerLink]="urls['report-history']">Export report history</a>
					<a *ngIf="sessionUser.isAdmin" class="dropdown-item" routerLinkActive="active" [href]="environment.apiUrl + '/admin'">Administration</a>
					<a class="dropdown-item" (click)="logout()">Logout</a>
				</div>
			</li>
		</ul>
	</div>
</nav>

<ng-template #navAbsContent let-item="item">
	<div class="dropdown-content" *ngIf="item.url === urls['abs-landing'] && sessionUser && sessionUser.userId">
		<h3>Current Study</h3>
		<ng-container *ngIf="!sessionUser || !sessionUser.userId; else loggedIn">
			<p>Results for the latest Benchmarking study are now available.</p>
			<a [routerLink]="urls['abs-summary']" class="btn btn--gold">View Summary</a>
		</ng-container>
		<ng-template #loggedIn>
			<ng-container *ngIf="absOpenReport && absOpenReport.isOpen; else showLatestAbs">
					<p>The {{ absOpenReport.year }} Benchmarking Study is now open and will close for submissions on {{ absOpenReport.close|date:'mediumDate':'utc' }}.</p>
					<a [routerLink]="urls['abs-submit']" class="btn btn--gold">Submit Data</a>
			</ng-container>

			<ng-template #showLatestAbs>
				<ng-container *ngIf="absLatestReport && absLatestReport.status == 2">
					<p>Results for the {{ absLatestReport.year }} Benchmarking study are now available.</p>
					<a [routerLink]="urls['abs-landing']" class="btn btn--gold">View Data</a>
				</ng-container>
				<ng-container *ngIf="absLatestReport && absLatestReport.status != 2">
					<p>The {{ absLatestReport.year }} Benchmarking Study is now closed. Results will be available soon.</p>
				</ng-container>
			</ng-template>
		</ng-template>
	</div>
</ng-template>

<ng-template #navFunnelContent let-item="item">
	<div class="dropdown-content" *ngIf="item.url === urls['funnel-landing']">
		<h3>Current Study</h3>
		<ng-container *ngIf="!sessionUser || !sessionUser.userId; else loggedIn">
			<p>Results for the latest funnel report are now available.</p>
			<a [routerLink]="urls['funnel-summary']" class="btn btn--gold">View Summary</a>
		</ng-container>
		<ng-template #loggedIn>
			<ng-container *ngIf="funnelReport && funnelReport.isOpen; else funnelReportClosed">
				<p>The {{ funnelReport.monthLabel }}, {{ funnelReport.year }} funnel report is now open and will close for submissions on {{ funnelReport.close|date:'mediumDate':'utc' }}.</p>
				<a [routerLink]="urls['funnel-report']" class="btn btn--gold">Submit Data</a>
			</ng-container>
			<ng-template #funnelReportClosed>
				<p *ngIf="funnelReport && funnelReport.isFinalReport">
					The Final Fall {{ funnelReport.reportYear }} report has closed.
				</p>
				<p *ngIf="funnelReport && !funnelReport.isFinalReport">
					The {{ funnelReport.monthLabel }} {{ funnelReport.reportYear }} report has closed.
				</p>
				<a [routerLink]="urls['funnel-landing']" class="btn btn--gold">View Data</a>
			</ng-template>
		</ng-template>
	</div>

	<a *ngIf="item.url === urls['funnel-landing']" href="/funnel/final-funnel-report-executive-summary" class="dropdown-item">
		2024 Final Funnel Report Executive Summary
	</a>
	<a *ngIf="item.url === urls['funnel-landing']" href="/funnel/ppt-report" class="dropdown-item">
		2024 Final Funnel PPT Report
	</a>
</ng-template>

<section class="body">
	<router-outlet></router-outlet>
</section>

<section *ngIf="homepageEntry" class="footer__wrapper">
	<div class="container footer">
		<div class="footer__left">
			<img [src]="homepageEntry.footerLogo | fullResourcePath" class="naccap_footer_logo">
			<div class="copyright">
				<p>{{ now | date:'yyyy' }} {{ homepageEntry.footerCopyright }}</p>
			</div>
			<div class="contact">
				<a [href]="homepageEntry.footerPhoneUrl">{{ homepageEntry.footerPhone }}</a>
			</div>
		</div>
		<div class="footer__right">
			<h2>{{ homepageEntry.footerRightTitle }}</h2>
			<div [innerHtml]="homepageEntry.footerRightContent"></div>
			<a class="btn btn--ghost btn--ghost--white" [routerLink]="urls['contact-us']">{{ homepageEntry.footerContactText }}</a>
		</div>
	</div>
</section>
